<template>
  <div class="page1">
    <!--主体内容-->
    <div class="content">
      <!--搜索条件区域-->
      <div class="searchWrapper" @keydown.enter.prevent="searchData">
        <el-form :inline="true" label-position="right" :model="formInline" class="demo-form-inline">
          <el-form-item label="应用名称" prop="applicationName">
            <el-input v-model.trim="formInline.applicationName" placeholder="请输入内容"></el-input>
          </el-form-item>
          <!--          TODO-->
          <el-form-item label="应用类型" prop="code">
            <el-select v-model.trim="formInline.code" filterable size="15">
              <el-option label="全部" value=""></el-option>
              <el-option
                :label="value.desc"
                :value="value.code"
                :key="value.code"
                v-for="value in enumsList"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="支付商户名称" prop="tenantId">
            <el-select v-model.trim="formInline.tenantId" filterable size="15">
              <el-option label="全部" value=""></el-option>
              <el-option
                :label="value.tenantName"
                :value="value.tenantId"
                :key="value.tenantId"
                v-for="value in tenantList"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="" prop="dealState">
            <el-button
              type="primary"
              icon="el-icon-search"
              @click="
                getData();
                page = 1;
              "
              :loading="loading"
              >{{ $t('button.search') }}</el-button
            >
            <el-button type="primary" icon="el-icon-delete" @click="resetForm">{{ $t('button.reset') }}</el-button>
            <!--<AuthorityComponent-->
            <!--ComponentName="el-button"-->
            <!--:permission="['button.create']"-->
            <!--style="float: right; padding: 3px 0"-->
            <!--type="text"-->
            <!--@click="add"-->
            <!--&gt;添加</AuthorityComponent>-->
            <el-button @click="add" type="primary">{{ $t('button.addto') }}</el-button>
          </el-form-item>
        </el-form>
      </div>
      <!--列表区域-->
      <div class="tableWrapper bgFFF paddingB10">
        <el-table
          header-cell-class-name="header-call-style"
          v-loading="loading"
          :data="tableData"
          style="width: 100%"
        >
          <el-table-column
            :prop="item.prop"
            :label="item.label"
            :width="item.width"
            v-for="item in tableCols"
            :key="item.prop"
            :formatter="item.formatter"
            align="center"
          ></el-table-column>
          <!--                           TODO-->
          <AuthorityComponent
            ComponentName="el-table-column"
            align="center"
            :permission="['button.detail', 'button.edit']"
            label="操作"
            width="80"
          >
            <template slot-scope="scope">
              <el-dropdown @command="handleCommand($event, scope.row)">
                <el-button type="primary" size="small"> 操作 </el-button>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item command="detail">查看</el-dropdown-item>
                  <el-dropdown-item command="edit">编辑</el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </template>
          </AuthorityComponent>
        </el-table>
        <div class="pagerWrapper" v-if="total > 0">
          <el-pagination
            @current-change="handleCurrentChange"
            :current-page="page"
            :page-size="pageSize"
            layout="total, prev, pager, next, jumper"
            :total="total"
          >
          </el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { dateFormat, exportExcelNew } from "../../common/js/public.js";

export default {
  name: "applicationConfiguration",
  data() {
    let startTime = new Date();
    startTime.setTime(startTime.getTime() - 31 * 24 * 60 * 60 * 1000);
    let date = startTime;
    return {
      page: 1,
      pageSize: 15,
      total: 0,
      index: 0,
      loading: false,
      detailFlag: false,
      areaList: [],
      parkList: [],
      tenantList: [],
      enumsList: [],
      rsTypeList: {},
      tableCols: [
        {
          prop: "applicationName",
          label: "应用名称",
          width: "",
        },
        {
          prop: "applicationTypeName",
          label: "应用类型",
          width: "",
        },
        {
          prop: "tenantName",
          label: "支付商户名称",
          width: "",
        },
      ],
      tableData: [],
      // TODO
      formInline: {
        applicationName: "",
        tenantId: "",
        code: "",
      },
      isEdit: false,
      isLook: false,
    };
  },
  methods: {
    // todo
    // 处理列表按钮点击
    handleCommand(event, row) {
      switch (event) {
        case "detail":
          this.toDetail(row.applicationId);
          break;
        case "edit":
          this.toEdit(row.applicationId);
          break;
        default:
          break;
      }
    },
    // add
    add() {
      this.$router.push({ path: "/appConfigDetail", query: { state: "add" } });
      this.isEdit = false;
    },
    // 进入详情页
    toDetail(applicationId) {
      this.$router.push({
        path: "/appConfigDetail",
        query: { state: "detail", applicationId: applicationId },
      });
      this.isLook = true;
    },
    // 进入编辑页
    toEdit(applicationId) {
      this.$router.push({
        path: "/appConfigDetail",
        query: { state: "edit", applicationId: applicationId },
      });
      this.isEdit = true;
    },
    // TODO
    resetForm() {
      this.formInline = {
        applicationName: "",
        name: "",
        tenantId: "",
        code: "",
      };
    },
    getTimeNow() {
      const endTime = new Date();
      if (this.formInline.dateType == "day") {
        endTime.setTime(endTime.getTime() - 3600 * 1000 * 24);
        this.formInline.day = dateFormat(endTime, "yyyy-MM-dd");
      } else {
        endTime.setTime(endTime.getTime() - 31 * 24 * 3600 * 1000);
        this.formInline.date = dateFormat(endTime, "yyyy-MM");
      }
    },
    /* 商户名称的下拉框 */
    getTenantList() {
      this.$axios.get("/acb/2.0/tenant/nameList").then((res) => {
        if (res.state == 0) {
          this.tenantList = res.value;
        } else {
          this.$alert(res.desc, this.$t('pop_up.Tips'), {
            confirmButtonText: this.$t('pop_up.Determine'),
          });
        }
      });
    },
    // 应用类型下拉框
    getenumsList() {
      this.$axios.get("/acb/2.0/application/enums").then((res) => {
        if (res.state == 0) {
          this.enumsList = res.value.applicationType;
        } else {
          this.$alert(res.desc, this.$t('pop_up.Tips'), {
            confirmButtonText: this.$t('pop_up.Determine'),
          });
        }
      });
    },
    handleCurrentChange(val) {
      this.page = val;
      this.getData();
    },
    getData() {
      this.$axios
        .get("/acb/2.0/application/list/", {
          data: {
            applicationName: this.formInline.applicationName,
            applicationType: this.formInline.code,
            tenantId: this.formInline.tenantId,
            page: this.page,
            pageSize: this.pageSize,
          },
        })
        .then((res) => {
          if (res.state != 0) {
            this.$alert(res.desc, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
          } else {
            this.tableData = res.value.list;
            this.total = res.value.total * 1 || 0;
          }
        });
    },
    exportFile() {
      let date = "";
      if (this.formInline.dateType == "month") {
        date = this.formInline.date;
      } else {
        date = this.formInline.day;
      }
      if (date == null) {
        this.$alert("日期不能为空！", this.$t('pop_up.Tips'), {
          confirmButtonText: this.$t('pop_up.Determine'),
        });
        return;
      }
      if (!this.formInline.dateType) {
        this.$alert("日期类型不能为空！", this.$t('pop_up.Tips'), {
          confirmButtonText: this.$t('pop_up.Determine'),
        });
        return;
      }
      let url =
        "/acb/2.0/financeStatistic/pdaManagers/report/" + this.formInline.dateType + "/" + date;
      let opt = {
        applicationName: this.formInline.applicationName,
        name: this.formInline.name,
        tenantId: this.formInline.tenantId,
      };
      exportExcelNew(url, opt);
    },
  },
  beforeDestroy() {},
  components: {},
  // activated () {
  //   this.getData();
  // },
  created() {
    this.getData();
    this.getTenantList();
    this.getenumsList();
    // if (sessionStorage.searchFlag == 1) {
    //   this.formInline = JSON.parse(sessionStorage.searchData);
    // }
  },
  activated() {
    this.getData();
  },
  computed: {},
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="stylus" rel="stylesheet/stylus">
.breadcrumb
  height 35px
.content
  overflow hidden
  .searchWrapper
    overflow hidden
    padding 22px 22px 0
    margin-bottom 20px
  .pagerWrapper
    text-align right
    margin-top 28px
    font-size 12px
    .export
      font-size 12px
      .iconfont
        margin-right 0
.detail
  width 61.4%
  height 82.9%
  position absolute
  top 8.6%
  left 20.1%
  background #FFFFFF
  box-shadow 0 0 4px 1px rgba(128, 145, 165, 0.3)
  border-radius 3px
  z-index 1000
  box-sizing border-box
  padding 20px 0
  .title
    position absolute
    background #3F4A56
    border-radius 3px 3px 0 0
    color #fff
    width 100%
    top 0
    left 0
    height 40px
    line-height 40px
    font-size 14px
    padding 0 12px
    box-sizing inherit
    z-index 100
    .el-icon-close
      float right
      margin-top 14px
  .info
    margin-top 20px
    padding 2.1%
    height 100%
    overflow auto
    box-sizing inherit
.mask
  background rgba(49, 53, 65, 0.6)
  position fixed
  top 0
  bottom 0
  left 0
  right 0
  z-index 999
.el-dropdown-link
  cursor pointer
</style>
